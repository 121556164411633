import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ScriptTag from 'react-script-tag';
import {Helmet} from "react-helmet";

import moment from "moment";
import cogoToast from 'cogo-toast';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

import GoogleMapReact from 'google-map-react';
import Map, { Marker,Popup } from "react-map-gl";
import Pin from "./pin";
import "mapbox-gl/dist/mapbox-gl.css";
import logo from '../Assets/logo.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar'
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import PlaceIcon from '@mui/icons-material/Place';


const currencyFormat = (num) => {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}




export default class Home extends Component {
  constructor(props) {
    super(props);


    this.state = {
        exercises: [],
        about_hotel: [],
        hotel_offer:[],
        carousel_header: [], 
        feedback: [], 
        Hotel_Information: '',
        Name_of_Hotel: '',
        map_value:'',
        map_address: '',
        email: '',
        mobile: '',
        tel_no: '',
        website: '',
        address: '',
        hotel_city: '',
        title: '',
        subtitles: '',
        link: '',
        background_image: '',
        selectedDate :null,
        dates_value: [null, null],
        guest: "", 
		 check_out: "", 
		 check_in: "", 
		 room: "",
         header_image: "",
         header_text: "",
         header_subtext: "",
         hotel_image: "",
         open: false,
      notif_tokens: '',
      Hotel_ID_details:{},
      Hotel_ID_Lux_details:{},
    Hotel_ID_TCalo_details:{},
    Hotel_ID_Ilang_details:{},
    allhoteldetails:[],
    map_margintop:150,
    showPopup:false,
    res:{},
    selectedhotel:'',
    valueTabs: 0

    };
  }
  handleChangeTabs = (event, newValue) => {
    this.setState({valueTabs: newValue});
  };
  handleNavigation = (event) => {

    console.log('event: ', event);
    this.setState({map_margintop:150})
    const content = document.getElementById("content");
    var scrolled = document.scrollingElement.scrollTop;
    var position = content.offsetTop;
  
    if(scrolled > position + 100){
      this.setState({map_margintop:100})
      }else{
        this.setState({map_margintop:150})
      }

}

  componentDidMount() {
    window.addEventListener("scroll",this.handleNavigation);
   

    axios.post('https://gloreto-api.onrender.com'+'/additional_info/GloretoHotels/')
      .then(response => {
        console.log("GloretoHotels: ",response)
        this.setState({ 
          allhoteldetails:response.data,
           
            })
      }) 
      .catch((error) => {
        console.log(error);
      })


     

  }


  render() {
 
    const { room, check_in, check_out, guest } = this.state;
    return (
        <body id="content" class="banner-area" >	
          
        <header id="header">
            <div class="header-top">
                <div class="container">
                  <div class="row align-items-center">
                      <div class="col-lg-12 col-sm-12 col-12 header-top-left">
                          <ul>
                              <li><a href="#">Visit Us</a></li>
                            
                          </ul>			
                      </div>
                      <div class="col-lg-12 col-sm-12 col-12 header-top-right">
                        <div class="header-social">
                            <a href="#"><i class="fa fa-facebook"></i></a>
                            <a href="#"><i class="fa fa-twitter"></i></a>
                            <a href="#"><i class="fa fa-dribbble"></i></a>
                            <a href="#"><i class="fa fa-behance"></i></a>
                        </div>
                      </div>
                  </div>			  					
                </div>
            </div>
            <div class="main-menu">
                <div class="row align-items-center justify-content-between d-flex">
                  <div id="logo">
                    <a href="index.html"><img src={logo} alt="" title="" /></a>
                  </div>
                  <nav id="nav-menu-container">
                    <ul class="nav-menu">
                      <li > <Link  to="/Home" style={{fontSize: 30}}>Gloreto Hotels</Link></li>
                    
                    </ul>
                  </nav>		
                  <button class="primary-btn text-uppercase">Butuan City, Philippines</button>		 		  
             		     
                   </div>
            </div>
        </header>

       
        <section >		
            <div class="container">
                <div>
                {window.innerWidth<992? <div style={{width: '45%', marginLeft: '30%', marginTop:150,}}>
                  <AppBar position="static" color="default">
                <Tabs value={this.state.valueTabs}
          onChange={this.handleChangeTabs}
          textColor="secondary"
          indicatorColor="secondary"
          >
  <Tab icon={<ArtTrackIcon />} label="LIST" />
  <Tab icon={<PlaceIcon />} label="MAP" />
</Tabs>
              
        </AppBar>
        </div>:null}


{this.state.valueTabs ==1 && window.innerWidth<992 ?null:
                    <div class="col-lg-6 col-md-6">
                        

                    <div style={{marginTop:window.innerWidth<992?50:150}}>
<div class="col-lg-6 col-xl-6  col-md-12 col-sm-12 list-of-rooms">
 {this.state.allhoteldetails.map((res, index)=>(



<div class="polaroid" style={{marginBottom:30, width:window.innerWidth<596?window.innerWidth/1.2:window.innerWidth<687?window.innerWidth/2:window.innerWidth<992?window.innerWidth/2.5:window.innerWidth/6, marginRight:50 }} onMouseEnter={()=>this.setState({selectedhotel:res._partition})} onMouseLeave={()=>this.setState({selectedhotel:''})}>
 <ImageGallery items={res.Hotel_images.map(({original, thumbnail}) => ({original, thumbnail }))}  defaultImage="http://www.wellesleysocietyofartists.org/wp-content/uploads/2015/11/image-not-found.jpg" 
                                    infinite={true}
                                    showBullets={true}
                                    showFullscreenButton={true}
                                    showPlayButton={false}
                                    showThumbnails={false}
                                    showIndex={true}
                                    showNav={false}
                                    slideDuration={450}
                                    slideInterval={2000}
                                    slideOnThumbnailOver={true}
                                    additionalClass="ImageGallery"
                                    useWindowKeyDown={true}/> 
  <div class="polaroid-container" style={{position:'relative'}}  >
    <h4 style={{fontFamily:'Yeseva-one'}}>{res.hotel_name}</h4>
    <a style={{color: 'blue', bottom:10, position:'absolute', left:'35%'}} href={res.website}>View Hotel</a>
  </div>
</div>


  
   ))}
  </div>
  </div>
 </div>


}








                        
                   
                    <div class="col-lg-6 col-md-6 banner-right" style={{ marginTop: this.state.map_margintop, position:'fixed',right: window.innerWidth<1200?-10:10, display:window.innerWidth<992?'none':'flex'}}>
                     {console.log("selectedhotel: ", this.state.selectedhotel)}
                    <Map
      initialViewState={{
        latitude:  8.952603664638975,
        longitude: 125.5394468683639,
        zoom: 14
      }}
      style={{ width: window.innerWidth <1200?(window.innerWidth-50)/2:window.innerWidth/2, height: window.innerHeight }}
      mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
    >
      {this.state.allhoteldetails.length > 0?
      this.state.allhoteldetails.map((info,index)=>
      {return <div onMouseEnter={()=>this.setState({showPopup: true, res:info})} onMouseLeave={()=>this.setState({showPopup: false})} onClick={(e) => {
       
        window.location.href=this.state.res.website;
        }} >
           <Marker longitude={info.lng} latitude={info.lat} i={index} color="red">
        <Pin changeSize={this.state.selectedhotel == info._partition?40:20} />
      </Marker>
      </div> } )
      :null
  } 

{this.state.showPopup && (
      <Popup longitude={this.state.res.lng} latitude={this.state.res.lat}
       
      onClose={() => this.setState({showPopup: false})}
      anchor="bottom" 
      
      >
        You are here

        <div class="general_CARDS">
      <h4>{this.state.res.hotel_name}</h4>
        <p>{this.state.res.header_text} <br /> <span> {this.state.res.header_subtext}</span></p>
          <div class="coords_CARDS">
          <span class="address">Mobile No: {this.state.res.mobile}</span>
            <span class="address">Tel No: {this.state.res.tel_no}</span>
          </div>
          <div class="coords_CARDS">
            <span class="address">Address: {this.state.res.address}</span>
            <span> </span>
          </div>
        <span class="more_CARDS">Click to go to website</span>
      </div>
      </Popup>)}
       
    </Map>			  	
                        
                    </div>
                    {this.state.valueTabs ==1 && window.innerWidth<992  ?

<Map
      initialViewState={{
        latitude:  8.952603664638975,
        longitude: 125.5394468683639,
        zoom: 15
      }}
      style={{ width: window.innerWidth-100, height: window.innerHeight, marginTop:50 }}
      mapStyle="https://basemaps.cartocdn.com/gl/positron-gl-style/style.json"
    >
      {this.state.allhoteldetails.length > 0?
      this.state.allhoteldetails.map((info,index)=>
      {return <div onMouseEnter={()=>this.setState({showPopup: true, res:info})} onMouseLeave={()=>this.setState({showPopup: false})} onClick={(e) => {
       
        window.location.href=this.state.res.website;
        }} >
           <Marker longitude={info.lng} latitude={info.lat} i={index} color="red">
        <Pin changeSize={this.state.selectedhotel == info._partition?40:20} />
      </Marker>
      </div> } )
      :null
  } 

{this.state.showPopup && (
      <Popup longitude={this.state.res.lng} latitude={this.state.res.lat}
       
      onClose={() => this.setState({showPopup: false})}
      anchor="bottom" 
      
      >
        You are here

        <div class="general_CARDS">
      <h4>{this.state.res.hotel_name}</h4>
        <p>{this.state.res.header_text} <br /> <span> {this.state.res.header_subtext}</span></p>
          <div class="coords_CARDS">
          <span class="address">Mobile No: {this.state.res.mobile}</span>
            <span class="address">Tel No: {this.state.res.tel_no}</span>
          </div>
          <div class="coords_CARDS">
            <span class="address">Address: {this.state.res.address}</span>
            <span> </span>
          </div>
        <span class="more_CARDS">Click to go to website</span>
      </div>
      </Popup>)}
       
    </Map>		:null

                }
                </div>
                
            </div>					
        </section>
     
     
    </body>
    )
  }
}