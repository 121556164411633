import React from 'react';
//import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route} from "react-router-dom";

import Home from "./components/home";


import './files/css/linearicons.css';
import './files/css/font-awesome.min.css';
import './files/css/bootstrap.css';
import './files/css/magnific-popup.css';
import './files/css/jquery-ui.css';
import './files/css/nice-select.css';
import './files/css/animate.min.css';
import './files/css/owl.carousel.css';
import './files/css/main.css';
import './files/css/tooplate-style.css';

function App() {

  
  return (
    <Router>

  
      <Route path="/" exact component={Home} />
     </Router>
  );
}

export default App;
